<template>
  <div>
    <div class="container small">
      <h1 class="page-headline">{{ product.productName }}</h1>
      <progressIndicator :step="product.step" :steps="product.steps" />
    </div>
    <template v-if="product.step == 1">
      <div class="container small">
        <!-- Infobesked -->
        <message
          :message="{
            headline: 'Har du husket indboforsikringen?',
            text: 'Vi gør opmærksom på, at du skal have en indboforsikring ved Aros Forsikring for at kunne tegne denne unikke campingvognsforsikring. ',
          }"
          type="info"
          v-on:formFunction="formFunction"
        />
      </div>
      <div class="container small">
        <div class="box">
          <FormStep
            :fields="product.fields"
            :product="product"
            :step="product.step"
            v-on:submitForm="submitStep1"
            v-on:formFunction="formFunction"
          />
        </div>

        <!-- Fejlbesked -->
        <message
          v-show="error.step1"
          :message="error.step1"
          type="warning"
          v-on:formFunction="formFunction"
        />
      </div>
    </template>
    <template v-else-if="product.step == 2">
      <div class="container small">
        <div class="box">
          <FormStep
            :fields="product.fields"
            :product="product"
            :step="product.step"
            v-on:submitForm="submitStep2"
            v-on:formFunction="formFunction"
          />
        </div>
        <!-- Fejlbesked -->
        <message
          v-show="error.step2"
          :message="error.step2"
          type="warning"
          v-on:formFunction="formFunction"
        />
      </div>
    </template>
    <template v-else-if="product.step == 3">
      <form id="step3" v-on:submit.prevent="submitStep3">
        <div class="container small">
          <div class="box">
            <!-- Felter på trin 3 -->
            <template v-for="(field, index) in product.fields">
              <FormInput
                v-if="field.step == 3"
                :data="field"
                :product="product"
                :key="index"
                v-on:formFunction="formFunction"
              />
            </template>
          </div>

          <!-- Fejlbesked -->
          <message
            v-show="error.price"
            :message="error.price"
            type="warning"
            v-on:formFunction="formFunction"
          />
        </div>

        <!-- Pakkekomponent -->
        <packages
          altLinkText="Læs mere om vores campingvognsforsikring"
          class="small"
          :packages="product.packages"
          :packageLink="product.packageLink"
          :requirementsMet="allRequirementsMet"
          v-on:packageChoice="choosePackage"
        />

        <!-- Extra packages -->
        <coverages
          v-model="product.coverages"
          :requirementsMet="allRequirementsMet"
          v-on:packageChoice="choosePackage"
        />
      </form>
    </template>

    <!-- Buttons on bottom -->
    <div class="container small" :class="{ small: product.step != 3 }">
      <ProgressButtons
        :step="product.step"
        :steps="product.steps"
        :existingPolicy="existingPolicy"
        @changeStep="changeStep"
      />
    </div>

    <!-- Product footer -->
    <product-footer
      :productName="product.productName"
      :productId="product.productId"
      :productLink="product.productLink"
      v-on:contactFromFooter="messageBox"
    >
      Hos os kan du tegne en alt-i-en campingvognsforsikring med
      kaskoforsikring, sumløs indboforsikring, redningshjælp i udlandet og
      retshjælp, så du er dækket for skader på selve campingvognen, forteltet og
      indboet i din campingvogn. Din præmie stiger ikke, hvis du er så uheldig
      at få en skade.
    </product-footer>
  </div>
</template>
<script>
import M71 from "@/assets/json/M71.json";
import FormInput from "@/components/FormInput.vue";
import progressIndicator from "@/components/progress-indicator.vue";
import ProgressButtons from "@/components/ProgressButtons.vue";
import message from "@/components/message.vue";
import productFooter from "@/components/product-footer.vue";
import packages from "@/components/packages.vue";
import FormStep from "@/components/FormStep.vue";
import debounce from "lodash/debounce";
import commonProduct from "@/mixins/commonProduct.js";
import validateData from "@/mixins/validateData.js";
import coverages from "@/components/coverages.vue";

export default {
  name: "Campingvognforsikring",
  components: {
    FormInput,
    progressIndicator,
    ProgressButtons,
    message,
    productFooter,
    packages,
    FormStep,
    coverages,
  },
  props: {
    policy: {
      type: Object,
      default: null,
    },
  },
  mixins: [commonProduct, validateData],
  data() {
    return {
      productId: "M71",
      product: this.copy(M71),
      temporaryProduct: this.copy(this.$store.state.temporaryProduct),
      isCaravan: true,
    };
  },
  created() {
    /*
     * If you edit and existing offer copy the policy here, or if you have temporary object
     */
    if (this.policy) {
      // Delete temporary product
      this.$store.commit("deleteTemporaryProduct");

      this.product = this.copy(this.policy.value);
      //this.existingPolicy = true;
      //this.existingPolicyKey = this.policy.key;
    } else if (
      !this.$route.query.input &&
      this.temporaryProduct &&
      this.temporaryProduct.productId == this.productId
    ) {
      this.product = this.copy(this.temporaryProduct);
    }
  },
  mounted() {
    // Function if input has come from website
    if (this.$route.query.input) {
      // Set registration number from input
      this.product.fields.registrationNumber.value = this.$route.query.input;

      // If value is set, run function
      if (this.product.fields.registrationNumber.value) {
        this.getRegistrationNumber();
      }

      // Get query and delete input
      let query = this.copy(this.$route.query);
      query.input = undefined;

      // Push to Veteranbilforsikring without input
      this.$router
        .push({ name: "Campingvognforsikring", query: query })
        .catch(() => {});
    }
  },
  computed: {
    /*
     * Check requirements
     */
    allRequirementsMet() {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(this.product.fields)) {
        if (value.required) {
          if (value.value === null || typeof value.value == 'undefined' || value.error) {
            this.cerror("Not all requirements met", value);
            return false;
          }
        }
      }

      if (!this.vehicleTypeIsCaravan()) {
        this.cerror("Not all requirements met", { isCaravan: this.isCaravan });
      }

      const r = this.vehicleTypeIsCaravan();

      this.cerror({ r });

      return r;
    },
  },
  watch: {
    "product.fields": {
      handler: function () {
        this.calculateEveryPrice();
        this.vehicleTypeIsCaravan();
      },
      deep: true,
    },
    "product.coverages": {
      handler: function () {
        this.calculateEveryPrice();
      },
      deep: true,
    },
    isCaravan() {
      this.vehicleTypeIsCaravan();
    },
  },
  methods: {
    /*
     * If registration data is empty
     */
    emptyRegistationData() {
      // Only if registration toggle is not set and registration number is filled out
      if (
        !this.product.fields.registrationToggle.value &&
        this.product.fields.registrationNumber.value
      ) {
        this.product.fields.registrationNumber.error =
          "Tryk på knappen for at hente oplysninger på din bil.";
      }
    },
    /*
     * Reset vehicle crm id field
     */
    resetVehicleCrmID() {
      this.product.fields.vehicleCrmID.value = null;
    },
    /*
     * Get data for registration number from api
     */
    getRegistrationNumber() {
      let self = this;

      // Reset car fields
      self.resetCarFields();

      // Remove error if it's there
      self.product.fields.registrationNumber.error = null;

      // Disable toogle button so that does not interfere
      self.product.fields.registrationToggle.disabled = true;

      // Set registration field to loading
      self.product.fields.registrationNumber.loading = true;

      // Create api string
      let apiString = `/api/vehicle/information/${self.product.fields.registrationNumber.value}`;

      // Get options list
      self.axios
        .get(apiString, {
          headers: {
            "x-ws-key": process.env.VUE_APP_KEY,
            Authorization: `Bearer ${self.$store.state.token}`,
          },
        })
        .then((response) => {
          self.product.fields.registrationData.value = response.data;
          self.product.fields.brandAndModel.value = `${response.data.extraCarInformation.maerke} ${response.data.extraCarInformation.model} ${response.data.extraCarInformation.variant} `;
          // Test type
          if (response.data.extraCarInformation.artsNavn) {
            this.isCaravan = response.data.extraCarInformation.artsNavn == "Campingvogn";
          }

          // Set model year if there
          if (response.data.data.modelYear) {
            self.$set(
              self.product.fields.modelYear,
              "value",
              response.data.data.modelYear
            );
          }

          // Set registration year if tehre
          if (response.data.extraCarInformation.foersteRegistreringDato) {
            self.$set(
              self.product.fields.registrationYear,
              "value",
              response.data.extraCarInformation.foersteRegistreringDato.substring(0, 4)
            );
          }

          // Set chassisNumber if there
          if (response.data.data.chassisNumber) {
            self.$set(
              self.product.fields.chassisNumber,
              "value",
              response.data.data.chassisNumber
            );
          }

          self.product.fields.registrationNumber.loading = false;

          // Enable toogle button
          self.product.fields.registrationToggle.disabled = false;
        })
        .then(() => {
          // Focus on vehicle crm field field
          //document.getElementById("vehicleCrmField").focus();
        })
        .catch(() => {
          self.product.fields.registrationNumber.error = `Vi kunne ikke finde køretøjsinformation for "${self.product.fields.registrationNumber.value.toUpperCase()}". Dobbelttjek at du har tastet det rigtige nummer ind i feltet.\nHvis du ikke kender din nummerplade, kan du finde din campingvogn manuelt ved at trykke på knappen nedenfor`;
          self.product.fields.registrationNumber.loading = false;

          // Enable toogle button
          self.product.fields.registrationToggle.disabled = false;
        });
    },
    /*
     * Reset car fields that is updated from registration field
     */
    resetCarFields() {
      this.isCaravan = true;
      this.product.fields.chassisNumber.value = null;
      //this.product.fields.vehicleCrmID.value = null;
      this.product.fields.brandAndModel.value = null;
      //this.product.fields.vehicleCrmField.selected = null;
      this.product.fields.modelYear.value = null;
      this.product.fields.registrationYear.value = null;
      this.product.fields.weightOwn.value = null;
      this.product.fields.vehicleName.value = null;
      this.product.fields.marketPrice.value = null;
      this.product.fields.use.value = null;
      this.product.fields.usedForRent.value = null;
      this.error.step1 = null;
    },
    // Function when you choose vehicle field
    vehicleCrmField(value) {
      this.product.fields.vehicleCrmID.value = value.key;
    },
    /*
     * Event when you choose a package
     */
    calculateEveryPrice: debounce(function () {
      let self = this;

      // Update temporary product if product is not existing
      if (!self.existingPolicy) {
        self.$store.commit("temporaryProduct", self.product);
      }

      // If all requirements are not met, break from function
      if (!self.allRequirementsMet) return;

      // Remove error
      self.error.price = null;

      // Get submit data without coverages
      let submitData = self.parseSubmitData(self.product);

      // Console log if in dev, test or beta environment
      if (
        process.env.NODE_ENV == "development" ||
        process.env.NODE_ENV == "test" ||
        process.env.NODE_ENV == "beta"
      ) {
        console.log(submitData);
      }

      // Create encrypted data
      let encryptedData = JSON.stringify(
        self.encrypt(JSON.stringify(submitData))
      );

      // Create api string
      let apiString = `/api/price`;

      self
        .axios({
          method: "post",
          url: apiString,
          headers: {
            "Content-Type": "application/json",
            "x-ws-key": process.env.VUE_APP_KEY,
            Authorization: `Bearer ${self.$store.state.token}`,
          },
          data: encryptedData,
        })
        .then((response) => {
          // If weird error shows, run function again
          if (response.data.objects && response.data.objects.length < 2) {
            self.calculateEveryPrice();
            return;
          }

          self.insertPrice(response.data);
        })
        .then(() => {
          // Show popup
          self.$store.commit("showPopup");

          // Update temporary product if product is not existing
          if (!self.existingPolicy) {
            self.$store.commit("temporaryProduct", self.product);
          }

          // If exiting policy, update existing policy
          if (self.existingPolicy) {
            self.updateExistingPolicy(self.product, self.existingPolicyKey);
          }
        })
        .catch(function () {
          // Show error
          self.error.price = {
            headline: "Vi kan desværre ikke beregne en pris online",
            text: "Det er desværre ikke muligt at beregne en pris online. Udfyld kontaktformularen og vores kundeservice kontakter dig for at give et tilbud på dine forsikringer.",
            button: {
              text: "Kontakt mig",
              function: "messageBox",
            },
          };

          // Remove loading for packages
          // eslint-disable-next-line no-unused-vars
          for (const [key, value] of Object.entries(self.product.packages)) {
            value.loading = null;
          }
        });
    }, 250),
    /*
     * Function for input on registration number
     */
    registrationNumber() {
      this.resetCarFields();
      this.product.fields.registrationData.value = null;
      this.product.fields.registrationNumber.error = null;
      this.error.step1 = null;

      // If the registration number is filled out, change to upper case
      if (this.product.fields.registrationNumber.value) {
        this.product.fields.registrationNumber.value =
          this.product.fields.registrationNumber.value.toUpperCase();
      }
    },
    /*
     * Blur function on registration number
     */
    leaveRegistrationNumber() {
      if (
        !this.product.fields.registrationData.value &&
        !this.product.fields.registrationNumber.loading &&
        this.product.fields.registrationNumber.value &&
        this.product.fields.registrationNumber.value.length > 1
      ) {
        this.getRegistrationNumber();
      }
    },
    /*
     * Function when the toggle for registration number changes
     */
    toggleRegistrationData() {
      // Reset car fields
      this.resetCarFields();

      if (this.product.fields.registrationToggle.value) {
        this.product.fields.brandAndModel.type = 'Text';
        //this.product.fields.modelYear.type = 'Select';
        this.product.fields.registrationYear.type = 'Number';

        this.product.fields.brandAndModel.required = true;
        //this.product.fields.modelYear.required = true;
        this.product.fields.registrationYear.required = true;

        // Remove required from registration number
        this.product.fields.registrationNumber.required = false;
        this.product.fields.registrationNumber.conditions = [
          "registrationData",
        ];

        // Remove required from registration number
        this.product.fields.registrationData.required = false;

        // Remove content of registration number
        this.product.fields.registrationNumber.value = null;
        this.product.fields.registrationNumber.error = null;

        // Remove content of registration data
        this.product.fields.registrationData.value = null;

        // Change requirements
        this.product.fields.brandAndModel.requires = [];
        this.product.fields.modelYear.requires = ["brandAndModel"];
        this.product.fields.registrationYear.requires = ["brandAndModel"];
        this.product.fields.marketPrice.requires = [
          "registrationYear",
          "brandAndModel"
        ];
      } else {
        this.product.fields.brandAndModel.type = 'Hidden';
        //this.product.fields.modelYear.type = 'Hidden';
        this.product.fields.registrationYear.type = 'Hidden';

        // Add required from registration number
        this.product.fields.registrationNumber.required = true;
        this.product.fields.registrationNumber.conditions = [];

        // Add required from registration number
        this.product.fields.registrationData.required = true;

        this.product.fields.brandAndModel.required = false;
        //this.product.fields.modelYear.required = false;
        this.product.fields.registrationYear.required = false;

        // Change requirements
        this.product.fields.brandAndModel.requires = ["registrationData"];
        this.product.fields.modelYear.requires = [
          "registrationData",
          "brandAndModel",
        ];
        this.product.fields.marketPrice.requires = [
          "registrationData",
          "brandAndModel"
        ];
      }
    },
    vehicleTypeIsCaravan() {
      const isCaravan = this.isCaravan;

      if (!isCaravan) {
        // Set error message
        this.error[`step1`] = {
          headline: "Der er fejl i registreringsnummeret",
          text: "Det indtastede registreringsnummer tilhører ikke en campingvogn. Indtast venligst registreringsnummeret på din campingvogn for at fortsætte. Har du brug for personlig rådgivning, kan du udfylde vores kontaktformular. Du vil herefter blive kontaktet af vores kundeservice med et tilbud på dine forsikringer.",
          button: {
            text: "Kontakt mig",
            function: "messageBox",
          },
        };

        return false;
      } else {
        // Set error message
        this.error[`step1`] = null;

        return true;
      }
    },
    // Returns boolean to show, whether the registration data has
    getVeteranPermission(registrationData) {
      try {
        let tilladelse = false;
        registrationData.extraCarInformation.tilladelse.forEach((element) => {
          if (element.tilladelseNummer == "14") tilladelse = true;
        });
        return tilladelse;
      } catch {
        return false;
      }
    },
    /*
     * Submit step 1 in form
     */
    async submitStep1() {
      let self = this;

      // Set step 1 to loading
      self.product.steps[0].loading = true;

      // Reset validation error
      self.validationError = null;

      // Reset error message
      self.error[`step${self.product.step}`] = null;

      // Validate the data from step 1
      let validationResult = await self.validateData(
        self.product,
        self.product.step
      );

      // Custom validation
      if (!this.vehicleTypeIsCaravan()) {
        self.product.steps[0].loading = false;
        return;
      }

      // Send customer along if valid, otherwise show error
      if (validationResult.isValid) {
        self.product.step += 1;
      } else {
        // Set validation error from response
        self.validationError = validationResult.message;

        // Set error message
        self.error[`step${self.product.step}`] = {
          headline: "Vi kan desværre ikke beregne en pris online",
          text: validationResult.message,
          button: {
            text: "Kontakt mig",
            function: "messageBox",
          },
        };
      }

      // Set loading to false
      self.product.steps[0].loading = false;
    },
    /*
     * Submit step 2 in form
     */
    async submitStep2() {
      let self = this;

      // Set step 1 to loading
      self.product.steps[1].loading = true;

      // Reset validation error
      self.validationError = null;

      // Reset error message
      self.error[`step${self.product.step}`] = null;

      // Validate the data from step 1
      let validationResult = await self.validateData(
        self.product,
        self.product.step
      );

      // Send customer along if valid, otherwise show error
      if (validationResult.isValid) {
        self.product.step += 1;
      } else {
        // Set validation error from response
        self.validationError = validationResult.message;

        // Set error message
        self.error[`step${self.product.step}`] = {
          headline: "Vi kan desværre ikke beregne en pris online",
          text: validationResult.message,
          button: {
            text: "Kontakt mig",
            function: "messageBox",
          },
        };
      }

      // Set step 1 to loading
      self.product.steps[1].loading = false;
    },
    /*
     * Submit step 3 in form
     */
    submitStep3() {
      // Check if policy is exitting or new
      if (this.existingPolicy) {
        this.updateExistingPolicy(this.product, this.existingPolicyKey);
      } else {
        this.$store.commit("addProduct", this.product);
      }

      // Delete temporary product
      this.$store.commit("deleteTemporaryProduct");

      // Route to shopping basket
      this.$router.push({ name: "Kurv" });
    },
    /*
     * Update existing policy
     */
    updateExistingPolicy(product, key) {
      this.$store.commit("updateProduct", {
        value: product,
        key: key,
      });
    },
  },
};
</script>
